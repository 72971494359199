<template>
  <div class="Footer">
    <div class="filings">
      <div class="item">版权所有：北京把车修好科技有限公司</div>
      <a class="item color" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备2021030791号-1</a>
      <a class="item police color" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802044316"
        target="_blank">京公网安备11010802044316号</a>
    </div>
    <div class="filings">
      <div class="item">公司名称：北京把车修好科技有限公司</div>
      <div class="item">公司地址：北京市朝阳区来广营乡来广营西路16号二层203</div>
      <!-- <div class="item">固定电话：010-XXXXXX</div> -->
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
/* 当视口宽度大于800px时应用这些样式 */
@media screen and (min-width: 500px) {
  .Footer {
    padding: 50px 20px 30px 20px;
    // background-color: #363D44;

    .filings {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;


      .item {
        font-size: 12px;
        line-height: 20px;
        margin: 0 10px;
        color: #1e4300;

        &.police {
          padding-left: 20px;
          background: url(../assets/images/base/police.png) left center no-repeat;
          background-size: 16px 16px;
        }

        &.color {
          cursor: pointer;

          &:hover {
            color: #008cff;
          }
        }
      }
    }
  }
}

/* 当视口宽度大于500px时应用这些样式 */
@media screen and (max-width: 800) {
  .Footer {
    background-color: green;
  }
}

/* 当视口宽度小于500px时应用这些样式 */
@media screen and (max-width: 500px) {
  .Footer {
    padding: 30px 20px 10px 20px;
    // background-color: #363D44;

    .filings {
      margin-bottom: 20px;


      .item {
        display: block;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 10px;

        &.police {
          padding-left: 20px;
          background: url(../assets/images/base/police.png) left center no-repeat;
          background-size: 16px 16px;
        }

        &.color {
          // color: #008cff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import(/* webpackChunkName: "home" */ "@/views/home/home.vue"),
  // },
  {
    path: "/", // 公众号解决方案
    name: "Index",
    component: () => import(/* webpackChunkName: "index" */ "@/views/index/index.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
